<!--
 * @Description: 客服管理
 * @Author: ChenXueLin
 * @Date: 2021-09-15 15:35:49
 * @LastEditTime: 2022-07-15 16:39:16
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="userId">
              <el-input
                v-model="searchForm.userId"
                placeholder="用户ID"
                title="用户ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userCode">
              <el-input
                v-model="searchForm.userCode"
                placeholder="用户工号"
                title="用户工号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userName">
              <el-input
                v-model="searchForm.userName"
                placeholder="客服名称"
                title="客服名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userPhone">
              <el-input
                v-model="searchForm.userPhone"
                placeholder="联系电话"
                title="联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userRoleCode">
              <e6-vr-select
                v-model="searchForm.userRoleCode"
                :data="userRoleList"
                placeholder="用户角色"
                title="用户角色"
                clearable
                :props="{
                  id: 'id',
                  label: 'name'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userStateCode">
              <e6-vr-select
                v-model="searchForm.userStateCode"
                :data="userStatus"
                placeholder="用户状态"
                title="用户状态"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getCallServiceList, getUserRoleList } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "customerServiceManage",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      total: 0,
      roleId: [3], //客服组id
      userRoleList: [], //角色下拉框
      userStatus: [
        {
          id: 1,
          label: "正常"
        },
        {
          id: 0,
          label: "禁用"
        }
      ],
      searchForm: {
        userId: "", //用户id
        userCode: "", //用户工号
        userName: "", //用户名称
        userPhone: "", //联系电话
        userRoleCode: "", //用户角色
        userStateCode: "", //用户状态
        createTime: [],
        beginTimeTimestamp: "", //开始时间
        endTimeTimestamp: "", //结束时间
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户id",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userCode",
          display: true,
          fieldLabel: "用户工号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "用户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userPhone",
          display: true,
          fieldLabel: "手机号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        // {
        //   fieldName: "userType",
        //   display: true,
        //   fieldLabel: "用户类型",
        //   width: 160,
        //   disabled: false,
        //   fixed: false,
        //   align: "center"
        // },
        {
          fieldName: "userRole",
          display: true,
          fieldLabel: "用户角色",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userState",
          display: true,
          fieldLabel: "用户状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "listenNum",
          display: true,
          fieldLabel: "本月接听来电",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "createdTimeString",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      refTable: "tableList",
      queryListAPI: getCallServiceList
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTimeTimestamp = val[0] || "";
        this.searchForm.endTimeTimestamp = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [getUserRoleList(this.roleId)];
        let [roleRes] = await Promise.all(promiseList);
        //角色下拉框
        this.userRoleList = this.getFreezeResponse(roleRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "userManage/userDetail",
          params: {
            refresh: true,
            userId: row.userId
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
